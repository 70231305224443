const navBarEntries = [
    {
        id: 1,
        text: 'Core API',
        target: '/apis/core'
    },
    // {
    //     id: 2,
    //     text: 'Contracts',
    //     target: '/apis/contracts'
    // },
    // {
    //     id: 3,
    //     text: 'Locations',
    //     target: '/apis/locations'
    // }
]

const routes = [
    {
        path: '/apis',
        name: 'APIs',
        component: () => import('./views/Home.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/apis/core',
        name: 'Core API',
        component: () => import('./views/CoreAPIPlayground.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    // {
    //     path: '/apis/contracts',
    //     name: 'Contracts API',
    //     component: () => import('./views/ContractsSwagger.vue'),
    //     meta: {
    //         nav: navBarEntries
    //     }
    // },
    // {
    //     path: '/apis/locations',
    //     name: 'Locations API',
    //     component: () => import('./views/LocationsSwagger.vue'),
    //     meta: {
    //         nav: navBarEntries
    //     }
    // }
]

export default routes