const navBarEntries = [
    {
        id: 1,
        text: 'Clients',
        target: '/contracts/clients'
    },
    {
        id: 2,
        text: 'Contracts',
        target: '/contracts/contracts'
    }
]

const routes = [
    {
        path: '/contracts/admin/products',
        name: 'Products',
        component: () => import('./views/Products.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/contracts/admin/product/:nid',
        name: 'Product',
        component: () => import('./views/Product.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/contracts/admin/product-groups',
        name: 'Product Groups',
        component: () => import('./views/ProductGroups.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/contracts/admin/product-group/:nid',
        name: 'Product Group',
        component: () => import('./views/ProductGroup.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/contracts/clients',
        name: 'Clients',
        component: () => import('./views/Clients.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/contracts/client/:nid',
        name: 'Client',
        component: () => import('./views/Client.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/contracts/contracts',
        name: 'Contracts',
        component: () => import('./views/Contracts.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/contracts/contract/:nid',
        name: 'Contract',
        component: () => import('./views/Contract.vue'),
        meta: {
            nav: navBarEntries
        }
    }
]

export default routes