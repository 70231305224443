const navBarEntries = [
    {
        id: 1,
        text: 'Revenue Accounts',
        target: '/billing/revenue-accounts'
    },
    {
        id: 2,
        text: 'Billing Articles',
        target: '/billing/billing-articles'
    }
]

const routes = [
    {
        path: '/billing/revenue-accounts',
        name: 'Revenue Accounts',
        component: () => import('./views/RevenueAccounts.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/billing/revenue-account/:nid',
        name: 'Revenue Account',
        component: () => import('./views/RevenueAccount.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/billing/billing-articles',
        name: 'Billing Articles',
        component: () => import('./views/BillingArticles.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/billing/billing-article/:nid',
        name: 'Billing Article',
        component: () => import('./views/BillingArticle.vue'),
        meta: {
            nav: navBarEntries
        }
    }
]

export default routes