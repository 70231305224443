const navBarEntries = [
    {
        id: 1,
        text: 'Account Owners',
        target: '/account-owners/account-owners'
    }
]

const routes = [
    {
        path: '/account-owners/account-owners',
        name: 'Account Owners',
        component: () => import('./views/AccountOwners.vue'),
        meta: {
            nav: navBarEntries
        }
    },
    {
        path: '/account-owners/account-owner/:nid',
        name: 'Account Owner',
        component: () => import('./views/AccountOwner.vue'),
        meta: {
            nav: navBarEntries
        }
    }
]

export default routes