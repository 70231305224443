const navBarEntries = [
    {
        id: 1,
        text: 'CoreAPI',
        target: 'admin/core-api'
    }
]

const routes = [
    {
        path: '/admin/core-api',
        name: 'CoreAPI',
        component: () => import('./views/CoreAPI.vue'),
        meta: {
            nav: navBarEntries
        }
    }
]

export default routes