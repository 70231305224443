import {
    cilAccountLogout,
    cilApps,
    cilLibrary,
    cilHome,
    cilMagnifyingGlass,
    cilCheck,
    cilPlus,
    cilPen,
    cilTrash,
    cibSwagger,
    cilZoom,
    cilPeople,
    cilLibraryBuilding,
    cilSettings,
    cilCaretBottom,
    cilCaretTop,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilXCircle,
    cilMoney
} from '@coreui/icons'

export const iconsSet = Object.assign(
    {},
    {
        cilAccountLogout,
        cilApps,
        cilLibrary,
        cilHome,
        cilMagnifyingGlass,
        cilCheck,
        cilPlus,
        cilPen,
        cilTrash,
        cibSwagger,
        cilZoom,
        cilPeople,
        cilLibraryBuilding,
        cilSettings,
        cilCaretBottom,
        cilCaretTop,
        cilChevronBottom,
        cilChevronTop,
        cilCheckCircle,
        cilXCircle,
        cilMoney
  }
)