<template>
  <div id="app">
    <SideBar/>
    <CWrapper>
      <Header/>
      <div class="c-body">
        <main class="c-main c-main-app">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
    </CWrapper>
  </div>
</template>

<script>
import Header from "./components/layout/Header";
import SideBar from "./components/layout/SideBar";
export default {
  name: "App",
  components: {
    Header,
    SideBar
  },
  beforeCreate() {
    localStorage.setItem('accessToken', this.$keycloak.token);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #747474;
}
.nav-bar-box{
  height: 5em;
}
.c-main-app{
  padding-top: 12px !important;
}
</style>
