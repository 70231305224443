import 'core-js/stable'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import CoreuiVue from '@coreui/vue'
import Multiselect from "vue-multiselect";
import './assets/scss/style.scss';
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import './plugins/keycloak-plugin';
import ApolloClient from 'apollo-boost';
import VueApollo from "vue-apollo";

Vue.config.performance = true;
Vue.config.productionTip = false;
Vue.use(CoreuiVue);
Vue.use(VueApollo);
Vue.component('multiselect', Multiselect);
Vue.prototype.$log = console.log.bind(console);

const apolloProvider = new VueApollo({
  defaultClient: new ApolloClient({
    uri: process.env.VUE_APP_GQL_ENDPOINT,
    request: async operation => {
    const token = localStorage.getItem('accessToken');
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    });
   }
  })
});

// with authentication
Vue.$keycloak
    .init({
        onLoad: 'login-required',
    })
    .then((authenticated) => {  
      new Vue({
        router,
        store,
        icons,
        apolloProvider,
        render: h => h(App)
      }).$mount('#app')
//Token Refresh
  setInterval(() => {
    Vue.$keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        const token = Vue.$keycloak.token
        localStorage.removeItem('accessToken');
        localStorage.setItem('accessToken', token);
      }
    }).catch(() => {
      console.error('Failed to refresh token');
    });
  }, 6000)
}).catch(() => {
  console.error("Authenticated Failed");
});
