<template>
<CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img src="../../assets/logo.png" height="42">
    </CSidebarBrand>
    <CSidebarNavItem
        data-sel="btn-nav-home"
        icon="cil-home"
        to="/"
        name="Home"
      > </CSidebarNavItem>
    <CSidebarNavItem
        data-sel="btn-nav-ao"
        icon="cil-people"
        to="/account-owners/account-owners"
        name="Account Owners"
      />
    <CSidebarNavItem
        data-sel="btn-nav-cntr"
        icon="cil-library"
        to="/contracts/contracts"
        name="Contracts"
      />
    <!--
    <CSidebarNavItem
        data-sel="btn-nav-blng"
        icon="cil-money"
        to="/billing/revenue-accounts"
        name="Billing"
      />
    -->
    <CSidebarNavItem
        data-sel="btn-nav-prtn"
        icon="cil-library-building"
        to="/partners/vendors"
        name="Partners"
      />
    <CSidebarNavItem
        data-sel="btn-nav-api"
        icon="cib-swagger"
        to="/apis"
        name="APIs"
      />
    <CSidebarNavDropdown
        data-sel="btn-nav-admn"
        icon="cil-settings"
        name="Admin"
      >
      <CSidebarNavItem
          data-sel="btn-nav-admn-api"
          to="/admin/core-api"
          name="View Data Refresh API"
        />
      <CSidebarNavDropdown
          data-sel="btn-nav-admn-cntr"
          name="Contracts"
        >
        <CSidebarNavItem
            data-sel="btn-nav-admn-cntr-prd"
            to="/contracts/admin/products"
            name="Products"
        />
        <CSidebarNavItem
            data-sel="btn-nav-admn-cntr-prdgrp"
            to="/contracts/admin/product-groups"
            name="Product Groups"
        />
        </CSidebarNavDropdown>
    </CSidebarNavDropdown>
    <CSidebarNavItem
      data-sel="btn-nav-lo"
      icon="cil-account-logout"
      name="Sign out"
      @click.native="signOut"
    />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
export default {
  name: "SideBar",
  methods: {
    signOut () {
      console.log('Logout')
      this.$keycloak.logout()
    }
  },
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  }
}
</script>

<style scoped>
.c-sidebar-nav-item {
  list-style-type: none;
}
.c-sidebar-nav-dropdown {
  list-style-type: none;
}
c-sidebar-nav-dropdown-items {
  margin-bottom: 0;
  background: #9c0725;
}
</style>