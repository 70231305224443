import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

// application routes
import routesAccountOwners from '../apps/account_owners/routes'
import routesContract from '../apps/contracts/routes'
import routesAPIs from '../apps/apis/routes'
import routesPartners from '../apps/partners/routes'
import routesAdmin from '../apps/admin/routes'
import routesBilling from '../apps/billing/routes'

Vue.use(VueRouter)

const routesDefault = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const routesValid = routesDefault.concat(
    routesAccountOwners, routesContract, routesAPIs, routesAdmin, routesPartners,
    routesBilling
)

const route404 = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Page404',
    component: () => import(/* webpackChunkName: "about" */ '../components/pages/Page404')
  }
]
const routes = routesValid.concat(route404)

const router = new VueRouter({
  routes
})

export default router
