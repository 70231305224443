<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderNav class="d-md-down-none mr-auto">
      <div v-bind:key="item.id" v-for="item in getNav()">
        <CHeaderNavItem class="d-md-down-none mx-2">
          <CHeaderNavLink
            v-bind:to="item.target">
            {{ item.text }}
          </CHeaderNavLink>
      </CHeaderNavItem>
      </div>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink to="/">
          <CIcon name="cil-home"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
  </CHeader>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      collapsed: false
    }
  },
  methods: {
    getNav() {
      if (typeof this.$route.matched[0] != "undefined") {
        return this.$route.matched[0].meta.nav
      }
    }
  }
}
</script>

<style scoped>

</style>